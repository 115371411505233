import './App.css';
import {BrowserRouter as Router,Route,Routes} from "react-router-dom";
import { Toaster } from 'react-hot-toast';

import { 
  Home, AllProperties, AddBlog, Blogs, BlogPage, Visits, Login, About, AddServices, 
  Signup, Wishlist, Services, WhatsappLogin, ResetPassword, Profile, Terms, Privacy, Listing, 
  PropertyDetail, MyProperties, View, Search 
} from './pages';
import Header from './components/header/Header';
import Footer from './components/footer/Footer';
import ScrollToTop from './util/ScrollToTop';
import SessionHelper from './components/SessionHelper';

const wrapComp=(children)=>{
  return(
    <>
      <Header/>
      {children}
      <Footer/>
    </>
  )
}

function App() {
  return (
    <Router>
      <Toaster/>
      <ScrollToTop/>
      <SessionHelper>
        <Routes>
          <>
            <Route path='/' element={ wrapComp(<Home/>) }/>
            <Route path='/login' element={<Login/>}/>
            <Route path='/whatsappLogin' element={<WhatsappLogin/>}/>
            <Route path='/resetpassword' element={<ResetPassword/>}/>
            <Route path='/signup' element={<Signup/>}/>
            <Route path='/profile' element={ wrapComp(<Profile/>) }/>
            <Route path='/terms&conditions' element={ wrapComp(<Terms/>) }/>
            <Route path='/privacypolicy' element={ wrapComp(<Privacy/>) }/>
            <Route path='/about' element={ wrapComp(<About/>) }/>
            <Route path='/addlisting' element={ wrapComp(<Listing/>) }/>
            <Route path='/propertydetail/:listed_id' element={ wrapComp(<PropertyDetail/>) }/>
            <Route path='/myproperties' element={ wrapComp(<MyProperties/>) }/>
            <Route path='/properties' element={ wrapComp(<AllProperties/>) }/>
            <Route path='/wishlist' element={ wrapComp(<Wishlist/>) }/>
            <Route path='/visits' element={ wrapComp(<Visits/>) }/>
            <Route path='/view' element={ wrapComp(<View/>) }/>
            <Route path='/services' element={ wrapComp(<Services/>) }/>
            <Route path='/addservices' element={ wrapComp(<AddServices/>) }/>
            <Route path='/search' element={ wrapComp(<Search/>) }/>
            <Route path='/blogs' element={ wrapComp(<Blogs/>) }/>
            <Route path='/blog' element={ wrapComp(<BlogPage/>) }/>
            <Route path='/addblog' element={ wrapComp(<AddBlog/>) }/>
          </>
        </Routes>
        </SessionHelper>
    </Router>
  );
}

export default App;
