import React, { useContext, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { MdSearch, MdNavigateNext, MdNavigateBefore } from "react-icons/md";
import {Helmet} from "react-helmet";

import './index.css';
import BlogCard from './../../components/blogCard/BlogCard';
import BlogCategoryCard from './../../components/blogCard/BlogCategoryCard';
import LoadingSpinner from '../../components/loadingSpinner/LoadingSpinner';
import { getBlogs } from '../../services/blogService';
import { AuthContext } from '../../context/authContext';

const Blogs = () => {
  const navigate = useNavigate();
  const {currentUser, locationVisibility} = useContext(AuthContext);
  const [searchParams] = useSearchParams();
  const [blogs,setBlogs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [search,setSearch] = useState('');
  const [page,setPage] = useState(searchParams.get('page')? parseInt(searchParams.get('page')) : 1);
  const [totalBlogs,setTotalBlogs] = useState(0);
  const [activeCategory,setActiveCategory] = useState([])
  const [category,setCategory] = useState("");


  const paginate=(dir)=>{
    if(dir===-1){
      setPage(page-1)
      navigate(`/blogs?page=${page-1}`); 
    }
    else{
      setPage(page+1)
      navigate(`/blogs?page=${page+1}`); 
    }
  }

  const handleCategory=(e)=>{
    setCategory(e.category);
    setPage(1);
  }


  const fetchBlogs= async() =>{
    setIsLoading(true);
    try{
      const res = await getBlogs(search,page,category);
      setBlogs(res.data.blogs);
      setTotalBlogs(res.data.total_blogs);
      setActiveCategory(res.data.active_categories);
      setIsLoading(false);
    }catch(err){
      window.alert("something went wrong");
      setIsLoading(false);
      console.log('getBlogs err---',err);
    }
  }

  useEffect(()=>{
    fetchBlogs();
  },[search,page,category]);

  useEffect(()=>{
    const changeLocation=async(visibility)=>{
      try{
        await locationVisibility(visibility);
      }
      catch(err){
        console.log('error in enabling location visibility')
      }
    }
    changeLocation(false);
  },[])


  return (
    <>
      <div className='blogs-page'>
        <Helmet>
          <title>{"Blogs"}</title>
          <meta name="description" content={"Blogs Page"}/>
          <link rel='canonical' href='/blog'/>
        </Helmet>
        <div className='blogs-page-head'>
          <h1>All Blog Posts</h1>
          <div className='create-blog-btn'>
          {
            currentUser && currentUser.isAdmin &&
            <Link to={'/addblog'}>Create Blog</Link>
          }
          </div>
        </div>
        
        <div className='blogs-page-content'>

          {/* blogs page left content */}
          <div className='blogs-page-left-content'>
            {/* home-page search */}
            <div className='blogpage-search-con'>
              <div className='blogpage-search-div'>
                <input name='search' value={search} onChange={(e)=>setSearch(e.target.value)} type='text' placeholder='Search Blog'/>
                <span className='blogpage-search-icon'><MdSearch/></span>
                <button onClick={()=>fetchBlogs()}>Search</button>
              </div>
            </div>

            {
              isLoading? <LoadingSpinner/>
              :
            <div className='blogs-cards'>
              {
                blogs.map((blog)=>{
                  return <BlogCard key={blog.id} blog={blog}/>
                })
              }
            </div>
            }

            {/* pagination */}
            {
              (blogs.length>0) &&
              <div className='pagination-sec'>
                <button disabled={page<2} onClick={()=>paginate(-1)}><MdNavigateBefore/>Prev</button>
                <span>. . . . {page} . . . .</span>
                <button disabled={totalBlogs<(15*page)} onClick={()=>paginate(1)}>Next <MdNavigateNext/></button>
              </div>
            }
          </div>

          {/* blogs page right content */}
          <div className='blogs-page-right-content'>
          {
            activeCategory.length>0 &&
            <div className='category-con'>
              <div className='right-content-heading'>
                <h2>Categories</h2>
              </div>
              <div className='category-content'>
                <ul>
                  {
                    activeCategory.map((data,index)=>{
                      return <li key={index}><Link onClick={(e)=>handleCategory(data)}>{data.category}</Link> </li>
                    })
                  }
                </ul>
              </div>
            </div>
          }
            {/* <div className='top-stories-con'>
              <div className='right-content-heading'>
                <h2>Top Stories</h2>
              </div>
              {
                isLoading? <LoadingSpinner/>
                :
              <div className='top-stories-content'>
                {
                  blogs.map((blog)=>{
                    return <BlogCategoryCard key={blog.id} blog={blog}/>
                  })
                }
              </div>
              }
            </div> */}
          </div>
        </div>
      </div>
    </>
  )
}

export default Blogs;