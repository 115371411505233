import axios from "axios";
import { BASE_URL } from "./helper";



// listing
const addListing = async (form) => {
  const res = await axios.post(`${BASE_URL}/listing/addListing`,form,{withCredentials:true},{
    headers: {
      'accept': 'application/json',
      'Accept-Language': 'en-US,en;q=0.8',
      'Content-Type': `multipart/form-data`,
    }
  
  });
  return res;
}

const updateListing = async (form) => {
  const res = await axios.post(`${BASE_URL}/listing/updateListing`,form,{withCredentials:true},{
    headers: {
      'accept': 'application/json',
      'Accept-Language': 'en-US,en;q=0.8',
      'Content-Type': `multipart/form-data`,
    }
  
  });
  return res;
}

const getListingById=async(id,user_id)=>{
  const res = await axios.get(`${BASE_URL}/listing/getListingById?id=${id}&user_id=${user_id}`,{withCredentials:true});
  return res;
}

const getListingContactDetails=async(id)=>{
  const res = await axios.get(`${BASE_URL}/listing/getListingContactDetails?id=${id}`,{withCredentials:true});
  return res.data;
}

const getListings=async(location,user_id)=>{
  const res = await axios.get(`${BASE_URL}/listing/getListings?location=${location}&user_id=${user_id}`,{withCredentials:true});
  return res;
}

const getListingByUserId=async(user_id)=>{
  const res = await axios.get(`${BASE_URL}/listing/getListingByUserId?user_id=${user_id}`,{withCredentials:true});
  return res;
}

const deleteListing=async(id)=>{
  const res = await axios.post(`${BASE_URL}/listing/deleteListing`,id,{withCredentials:true});
  return res;
}

// wishlist
const addToWishlist=async(listing_id,user_id)=>{
  const res = await axios.post(`${BASE_URL}/listing/addToWishlist`,{listing_id,user_id},{withCredentials:true});
  return res;
}

const getWishlist=async(user_id)=>{
  const res = await axios.get(`${BASE_URL}/listing/getWishListByUserId?user_id=${user_id}`,{withCredentials:true});
  return res;
}

// visits
const getVisits=async(user_id)=>{
  const res = await axios.get(`${BASE_URL}/listing/getVisitsByUserId?user_id=${user_id}`,{withCredentials:true});
  return res;
}



// service
const addService = async (form) => {
  const res = await axios.post(`${BASE_URL}/services/addService`,form,{withCredentials:true},{
    headers: {
      'accept': 'application/json',
      'Accept-Language': 'en-US,en;q=0.8',
      'Content-Type': `multipart/form-data`,
    }
  });
  return res;
}

const deleteService=async(service_id)=>{
  const res = await axios.post(`${BASE_URL}/services/deleteService`,{service_id},{withCredentials:true});
  return res;
}

const getServices=async(location)=>{
  const res = await axios.get(`${BASE_URL}/services/getServices?location=${location}`,{withCredentials:true});
  return res;
}




// search
const searchProperties=async(payload)=>{
  const res = await axios.post(`${BASE_URL}/listing/searchProperties`,{...payload},{withCredentials:true});
  return res;
}

// set visits
const setVisits=async(payload)=>{
  const res = await axios.post(`${BASE_URL}/listing/setVisits`,{...payload},{withCredentials:true});
  return res;
}





export { 
  setVisits,
  addListing, 
  getListingById, 
  getListings, 
  updateListing, 
  getListingByUserId, 
  deleteListing, 
  addToWishlist, 
  getWishlist,
  addService,
  deleteService,
  getServices,
  searchProperties,
  getVisits,
  getListingContactDetails
};