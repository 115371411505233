import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {Helmet} from "react-helmet";

import './index.css';
import Card2 from '../../components/card2/Card2';
import { AuthContext } from '../../context/authContext';
import { getListingByUserId } from '../../services/listingService';
import LoadingSpinner from '../../components/loadingSpinner/LoadingSpinner';


const MyProperties = () => {
  const {currentUser} = useContext(AuthContext);
  const navigate = useNavigate();
  const [properties,setProperties] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(()=>{
    const fetchMyProperties= async() =>{
      setIsLoading(true);
      try{
        const res = await getListingByUserId(currentUser.id);
        setProperties(res.data.usersListing);
        setIsLoading(false);
      }catch(err){
        window.alert("something went wrong");
        navigate("/whatsappLogin");
        setIsLoading(false);
        console.log('myproperties err---',err);
      }
    }

    currentUser && fetchMyProperties();
  },[currentUser]);

  return (
    <>
      <div className='myproperties-page'>
        <Helmet>
          <title>{"My Properties"}</title>
          <meta name="description" content={"My Properties"}/>
          <link rel='canonical' href='/myproperties'/>
        </Helmet>
        <div className='myproperties-page-head'>
          <h1>My Properties</h1>
        </div>
        {
          isLoading? <div className='home-loader'><LoadingSpinner/></div>
          :
          <>
          {
            properties?
            <div className='myproperties-page-content'>
              <div className='myproperties-cards'>
                {
                  properties.map((property)=>{
                    return <Card2 key={property.id} property={property} edit={1}/>
                  })
                }
              </div>
            </div>
            :
            ''
          }
          </>
        }
      </div>
    </>
  )
}

export default MyProperties;