import React, { useContext, useEffect, useState } from 'react';
import { MdSearch } from "react-icons/md";
import { HiOutlineAdjustmentsHorizontal } from "react-icons/hi2";
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import {MdCurrencyRupee } from "react-icons/md";
import {Helmet} from "react-helmet";

import '../MyProperties/index.css';
import '../Home/index.css';
import './index.css';
import Card2 from '../../components/card2/Card2';
import { AuthContext } from '../../context/authContext';
import { searchProperties } from '../../services/listingService';
import LoadingSpinner from '../../components/loadingSpinner/LoadingSpinner';
import Filter from '../../components/filter/Filter';
import cities from '../../data/cities.json';
import { 
  propertyTypesConstant, furnishingStatusConstant, brokerageConstants, 
  foodAllowedConstant, tenantTypeConstant, listedByConstant, configurationConstant 
} from '../../constants/property';
import { useLocation } from 'react-router-dom';
import { Input, TextField } from '@mui/material';


const SearchProperties = () => {
  const state = useLocation().state || {};
  const {currentUser, currentLocation, locationVisibility, searchFilters, setSearchFilters} = useContext(AuthContext);
  const [properties,setProperties] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [popup,setPopup] = useState(false);
  const [filters,setFilters] = useState(searchFilters?.filters || {});
  const [search,setSearch] = useState((searchFilters?.search) || (state.search && state.search));
  const [city,setCity] = useState(searchFilters?.city || currentLocation);
  const [range, setRange] = useState(searchFilters?.range || [1000, 1000000]);

  const handleRangeChange = (newRange) => {
    setRange(newRange);
    setFilters({...filters,min_rent:newRange[0],max_rent:newRange[1]});
  };


  let name,value;
  const handleInput=(e)=>{
    e.preventDefault();
    name= e.target.name;
    value= e.target.value;
    setFilters({...filters, [name]:value.length? value: null})
  }
  const handleProperty=(e)=>{
    e.preventDefault();
    name= e.target.name;
    value= e.target.value;
    if(name==='property_type'){
      setFilters(filters.property_type===value? {...filters,property_type:null} : {...filters,property_type:value});
    }
    if(name==='listed_by'){
      setFilters(filters.listed_by===value? {...filters,listed_by:null} : {...filters,listed_by:value});
    }
    if(name==='configuration'){
      setFilters(filters.configuration===value? {...filters,configuration:null} : {...filters,configuration:value});
    }
    if(name==='tenant_type'){
      setFilters(filters.tenant_type===value? {...filters,tenant_type:null} : {...filters,tenant_type:value});
    }
    if(name==='food_allowed'){
      setFilters(filters.food_allowed===value? {...filters,food_allowed:null} : {...filters,food_allowed:value});
    }
    if(name==='furnishing_status'){
      setFilters(filters.furnishing_status===value? {...filters,furnishing_status:null} : {...filters,furnishing_status:value});
    }
    if(name==='brokerage'){
      setFilters(filters.brokerage===value? {...filters,brokerage:null} : {...filters,brokerage:value});
    }
  }

  const fetchProperties= async() =>{
    setIsLoading(true);
    setPopup(false);
    setSearchFilters({filters,search,city,range});
    try{
      const res = await searchProperties({user_id:currentUser? currentUser.id : null,location:city,search,...filters});
      setProperties(res.data.properties);
      setIsLoading(false);
    }catch(err){
      window.alert("something went wrong");
      setIsLoading(false);
      console.log('search err---',err);
    }
  }

  const handleResetFilter = (e)=>{
    e.preventDefault();
    setFilters({});
    setSearchFilters({})
    setRange([1000, 9999999]);
    fetchProperties();
  }
  
  useEffect(()=>{
    fetchProperties();
  },[search,]);

  useEffect(()=>{
    const changeLocation=async(visibility)=>{
      try{
        await locationVisibility(visibility);
      }
      catch(err){
        console.log('error in enabling location visibility')
      }
    }
    changeLocation(false);
  },[])


  return (
    <>
      <div className='search-page'>
        <Helmet>
          <title>{"Search Property"}</title>
          <meta name="description" content={"Search Property"}/>
          <link rel='canonical' href='/search'/>
        </Helmet>
        <div className='myproperties-page'>
          <div className='myproperties-page-head'>
            {/* home-page search */}
            <div className='homepage-search-con'>
              <div className='search-div'>
                <input value={search} autoFocus onChange={(e)=>setSearch(e.target.value)} type='text' placeholder='Search House, Apartments etc.'/>
                <span className='search-icon'><MdSearch/></span>
                <span onClick={()=>setPopup(true)} className='filter-icon'><HiOutlineAdjustmentsHorizontal/></span>
                <button onClick={fetchProperties}>Search</button>
              </div>
            </div>
          </div>

          {/* content */}
          {
            isLoading? <div className='home-loader'><LoadingSpinner/></div>
            :
            <>
            {
              properties?
              <div className='myproperties-page-content'>
                <div className='myproperties-cards'>
                  {
                    properties.map((property)=>{
                      return <Card2 key={property.id} property={property}/>
                    })
                  }
                </div>
              </div>
              :
              ''
            }
            </>
          }

        </div>
        
        {/* filter popup */}
        <Filter className='filter-pop' trigger={popup} setTrigger={setPopup}>
          <div className='filter-fields'>
            <div className='filter-col'>
              <select name='city' value={city || ''} onChange={(e)=>{setCity(e.target.value)}}>
                {cities.map((city)=>{
                  return <option key={city.id} value={city.name}>{city.name}</option>
                })}
              </select>
              <input type='text' value={filters.locality || ''} onChange={handleInput} name='locality' placeholder='Search Locality or Landmarks'/>
              <div className='selection-field'>
                <div className='selection-head'>
                  <h2>Looking For</h2>
                </div>
                <div className='selection-options'>
                  <input onChange={handleProperty} checked={filters.property_type===propertyTypesConstant.APARTMENT} type='checkbox' id='apartment' name='property_type' value={propertyTypesConstant.APARTMENT}/>
                  <label className={filters.property_type===propertyTypesConstant.APARTMENT && 'selected-label'} htmlFor={'apartment'}>{propertyTypesConstant.APARTMENT}</label>
                  <input onChange={handleProperty} checked={filters.property_type===propertyTypesConstant.PG_HOSTEL} type='checkbox' id='pgHostel' name='property_type' value={propertyTypesConstant.PG_HOSTEL}/>
                  <label className={filters.property_type===propertyTypesConstant.PG_HOSTEL && 'selected-label'} htmlFor={'pgHostel'}>{propertyTypesConstant.PG_HOSTEL}</label>
                  <input onChange={handleProperty} checked={filters.property_type===propertyTypesConstant.WEEKEND_HOME} type='checkbox' id='weekendHome' name='property_type' value={propertyTypesConstant.WEEKEND_HOME}/>
                  <label className={filters.property_type===propertyTypesConstant.WEEKEND_HOME && 'selected-label'} htmlFor={'weekendHome'}>{propertyTypesConstant.WEEKEND_HOME}</label>
                </div>
              </div>
              <div className='selection-field'>
                <div className='selection-head'>
                  <h2>Property Listed by</h2>
                </div>
                <div className='selection-options'>
                  <input onChange={handleProperty} checked={filters.listed_by===listedByConstant.OWNER} type='checkbox' id='owner' name='listed_by' value={listedByConstant.OWNER}/>
                  <label className={filters.listed_by===listedByConstant.OWNER && 'selected-label'} htmlFor={'owner'}>{listedByConstant.OWNER}</label>
                  <input onChange={handleProperty} checked={filters.listed_by===listedByConstant.BROKER} type='checkbox' id='broker' name='listed_by' value={listedByConstant.BROKER}/>
                  <label className={filters.listed_by===listedByConstant.BROKER && 'selected-label'} htmlFor={'broker'}>{listedByConstant.BROKER}</label>
                  <input onChange={handleProperty} checked={filters.listed_by===listedByConstant.FLATMATE} type='checkbox' id='flatmate' name='listed_by' value={listedByConstant.FLATMATE}/>
                  <label className={filters.listed_by===listedByConstant.FLATMATE && 'selected-label'} htmlFor={'flatmate'}>{listedByConstant.FLATMATE}</label>
                </div>
              </div>
              <div className='selection-field'>
                <div className='selection-head'>
                  <h2>Rooms</h2>
                </div>
                <div className='selection-options'>
                {
                  filters.property_type === propertyTypesConstant.PG_HOSTEL?
                  <>
                    <input onChange={handleProperty} checked={filters.configuration===configurationConstant.SINGLE_SHARING} type='checkbox' id='singleSharing' name='configuration' value={configurationConstant.SINGLE_SHARING}/>
                    <label className={filters.configuration===configurationConstant.SINGLE_SHARING && 'selected-label'} htmlFor={'singleSharing'}>{configurationConstant.SINGLE_SHARING}</label>
                    <input onChange={handleProperty} checked={filters.configuration===configurationConstant.DOUBLE_SHARING} type='checkbox' id='doubleSharing' name='configuration' value={configurationConstant.DOUBLE_SHARING}/>
                    <label className={filters.configuration===configurationConstant.DOUBLE_SHARING && 'selected-label'} htmlFor={'doubleSharing'}>{configurationConstant.DOUBLE_SHARING}</label>
                    <input onChange={handleProperty} checked={filters.configuration===configurationConstant.TRIPLE_SHARING} type='checkbox' id='doubleSharing' name='configuration' value={configurationConstant.TRIPLE_SHARING}/>
                    <label className={filters.configuration===configurationConstant.TRIPLE_SHARING && 'selected-label'} htmlFor={'doubleSharing'}>{configurationConstant.TRIPLE_SHARING}</label>
                    <input onChange={handleProperty} checked={filters.configuration===configurationConstant.FOUR_SHARING} type='checkbox' id='doubleSharing' name='configuration' value={configurationConstant.FOUR_SHARING}/>
                    <label className={filters.configuration===configurationConstant.FOUR_SHARING && 'selected-label'} htmlFor={'doubleSharing'}>{configurationConstant.FOUR_SHARING}</label>
                  </>
                  : 
                  <>
                    <input onChange={handleProperty} checked={filters.configuration===configurationConstant._1BHK} type='checkbox' id='1bhk' name='configuration' value={configurationConstant._1BHK}/>
                    <label className={filters.configuration===configurationConstant._1BHK && 'selected-label'} htmlFor={'1bhk'}>{configurationConstant._1BHK}</label>
                    <input onChange={handleProperty} checked={filters.configuration===configurationConstant._2BHK} type='checkbox' id='2bhk' name='configuration' value={configurationConstant._2BHK}/>
                    <label className={filters.configuration===configurationConstant._2BHK && 'selected-label'} htmlFor={'2bhk'}>{configurationConstant._2BHK}</label>
                    <input onChange={handleProperty} checked={filters.configuration===configurationConstant._3BHK} type='checkbox' id='3bhk' name='configuration' value={configurationConstant._3BHK}/>
                    <label className={filters.configuration===configurationConstant._3BHK && 'selected-label'} htmlFor={'3bhk'}>{configurationConstant._3BHK}</label>
                    <input onChange={handleProperty} checked={filters.configuration===configurationConstant._4BHK} type='checkbox' id='4bhk' name='configuration' value={configurationConstant._4BHK}/>
                    <label className={filters.configuration===configurationConstant._4BHK && 'selected-label'} htmlFor={'4bhk'}>{configurationConstant._4BHK}</label>
                    <input onChange={handleProperty} checked={filters.configuration===configurationConstant._5BHK} type='checkbox' id='4bhk' name='configuration' value={configurationConstant._5BHK}/>
                    <label className={filters.configuration===configurationConstant._5BHK && 'selected-label'} htmlFor={'4bhk'}>{configurationConstant._5BHK}</label>
                    <input onChange={handleProperty} checked={filters.configuration===configurationConstant._6BHK} type='checkbox' id='4bhk' name='configuration' value={configurationConstant._6BHK}/>
                    <label className={filters.configuration===configurationConstant._6BHK && 'selected-label'} htmlFor={'4bhk'}>{configurationConstant._6BHK}</label>
                  </>
                }
                  
                </div>
              </div>

            </div>

            <div className='filter-col'>
              <div className='selection-field'>
                <div className='selection-head'>
                  <h2>Tenant Type</h2>
                </div>
                <div className='selection-options'>
                  <input onChange={handleProperty} checked={filters.tenant_type===tenantTypeConstant.MALE} type='checkbox' id='male' name='tenant_type' value={tenantTypeConstant.MALE}/>
                  <label className={filters.tenant_type===tenantTypeConstant.MALE && 'selected-label'} htmlFor={'male'}>{tenantTypeConstant.MALE}</label>
                  <input onChange={handleProperty} checked={filters.tenant_type===tenantTypeConstant.FEMALE} type='checkbox' id='Female' name='tenant_type' value={tenantTypeConstant.FEMALE}/>
                  <label className={filters.tenant_type===tenantTypeConstant.FEMALE && 'selected-label'} htmlFor={'Female'}>{tenantTypeConstant.FEMALE}</label>
                  <input onChange={handleProperty} checked={filters.tenant_type===tenantTypeConstant.NO_PREFERENCE} type='checkbox' id='any' name='tenant_type' value={tenantTypeConstant.NO_PREFERENCE}/>
                  <label className={filters.tenant_type===tenantTypeConstant.NO_PREFERENCE && 'selected-label'} htmlFor={'any'}>{'Any'}</label>
                </div>
              </div>
              <div className='selection-field'>
                <div className='selection-head'>
                  <h2>Food Preference</h2>
                </div>
                <div className='selection-options'>
                  <input onChange={handleProperty} checked={filters.food_allowed===foodAllowedConstant.VEG} type='checkbox' id='veg' name='food_allowed' value={foodAllowedConstant.VEG}/>
                  <label className={filters.food_allowed===foodAllowedConstant.VEG && 'selected-label'} htmlFor={'veg'}>{foodAllowedConstant.VEG}</label>
                  <input onChange={handleProperty} checked={filters.food_allowed===foodAllowedConstant.NONVEG} type='checkbox' id='nonveg' name='food_allowed' value={foodAllowedConstant.NONVEG}/>
                  <label className={filters.food_allowed===foodAllowedConstant.NONVEG && 'selected-label'} htmlFor={'nonveg'}>{foodAllowedConstant.NONVEG}</label>
                </div>
              </div>
              <div className='selection-field'>
                <div className='selection-head'>
                  <h2>Furnishing Status</h2>
                </div>
                <div className='selection-options'>
                  <input onChange={handleProperty} checked={filters.furnishing_status===furnishingStatusConstant.FULLY_FURNISHED} type='checkbox' id='fullyfurnished' name='furnishing_status' value={furnishingStatusConstant.FULLY_FURNISHED}/>
                  <label className={filters.furnishing_status===furnishingStatusConstant.FULLY_FURNISHED && 'selected-label'} htmlFor={'fullyfurnished'}>{furnishingStatusConstant.FULLY_FURNISHED}</label>
                  <input onChange={handleProperty} checked={filters.furnishing_status===furnishingStatusConstant.SEMI_FURNISHED} type='checkbox' id='semifurnished' name='furnishing_status' value={furnishingStatusConstant.SEMI_FURNISHED}/>
                  <label className={filters.furnishing_status===furnishingStatusConstant.SEMI_FURNISHED && 'selected-label'} htmlFor={'semifurnished'}>{furnishingStatusConstant.SEMI_FURNISHED}</label>
                  <input onChange={handleProperty} checked={filters.furnishing_status===furnishingStatusConstant.UNFURNISHED} type='checkbox' id='unfurnished' name='furnishing_status' value={furnishingStatusConstant.UNFURNISHED}/>
                  <label className={filters.furnishing_status===furnishingStatusConstant.UNFURNISHED && 'selected-label'} htmlFor={'unfurnished'}>{'None'}</label>
                </div>
              </div>
              <div className='selection-field'>
                <div className='selection-head'>
                  <h2>Brokrage</h2>
                </div>
                <div className='selection-options'>
                  <input onChange={handleProperty} checked={filters.brokerage===brokerageConstants.ZERO} type='checkbox' id='zero' name='brokerage' value={brokerageConstants.ZERO}/>
                  <label className={filters.brokerage===brokerageConstants.ZERO && 'selected-label'} htmlFor={'zero'}>{brokerageConstants.ZERO}</label>
                  <input onChange={handleProperty} checked={filters.brokerage===brokerageConstants._15_DAYS_RENT} type='checkbox' id='15DaysRent' name='brokerage' value={brokerageConstants._15_DAYS_RENT}/>
                  <label className={filters.brokerage===brokerageConstants._15_DAYS_RENT && 'selected-label'} htmlFor={'15DaysRent'}>{brokerageConstants._15_DAYS_RENT}</label>
                  <input onChange={handleProperty} checked={filters.brokerage===brokerageConstants._30_DAYS_RENT} type='checkbox' id='30DaysRent' name='brokerage' value={brokerageConstants._30_DAYS_RENT}/>
                  <label className={filters.brokerage===brokerageConstants._30_DAYS_RENT && 'selected-label'} htmlFor={'30DaysRent'}>{brokerageConstants._30_DAYS_RENT}</label>
                </div>
              </div>
              {/* range */}
              <div className='selection-field'>
                <div className='selection-head'>
                  <h2>Rent Range</h2>
                </div>
                <div className='selection-options rent-ranger'>
                  <div className='tooltips'>
                    {/* <span className='min'><MdCurrencyRupee/> {range[0]}</span> */}
                    <TextField value={range[0]} variant='standard' 
                      onChange={(e)=>setRange([e.target.value, range[1]])}
                      InputProps={{
                        style: {
                          border: 'none',
                        },
                      }}
                    />
                    -
                    <TextField value={range[1]} variant='standard'
                      onChange={(e)=>setRange([range[0],e.target.value])}
                    />
                  </div>
                  <Slider
                    min={1000}
                    max={1000000}
                    step={100}
                    range={range}
                    defaultValue={range}
                    onChange={handleRangeChange}
                    className='rent-ranger'
                  />
                </div>
              </div>

            </div>
          </div>

            {/* filter buttons */}
            <div className='filter-btns'>
              <button onClick={fetchProperties} className='btn1'>Filter</button>
              <button onClick={handleResetFilter} className='btn2'>Clear Filter</button>
            </div>
        </Filter>
      </div>
    </>
  )
}

export default SearchProperties;