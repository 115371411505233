import React from 'react';
import { MdClose } from "react-icons/md";


import './Filter.css';

const Filter = (props) => {
  return (props.trigger) ? (
    <div className='filter-popup'>
      <div className='filter-body'>
        <button onClick={()=>props.setTrigger(false)} className='close-btn'>
          <MdClose className='icon'/>
        </button>
        {props.children} 
      </div>
    </div>
  )
  : 
  ""
}

export default Filter