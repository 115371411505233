import React,{useContext, useState} from 'react';
import { Link } from 'react-router-dom';
import {MdCurrencyRupee, MdLocationOn } from "react-icons/md";
import { FaWallet } from "react-icons/fa";
import { AiOutlineHeart,AiFillHeart } from "react-icons/ai";
import toast from 'react-hot-toast';

import './Card.css';
import { AuthContext } from '../../context/authContext';
import { addToWishlist } from '../../services/listingService';
import { propertyTypesConstant } from '../../constants/property';

const Card = (props) => {
  const {currentUser} = useContext(AuthContext);
  let propertyDetails = props.property? props.property : [];
  const [reload, setReload] = useState(false);

  const reloadEffect = () => {
    setReload(!reload);
  };

  // const handleDelete = async(e)=>{
  //   e.preventDefault();
  //   try{
  //     const delRes = await deleteListing({id:propertyDetails.id});
  //     if(delRes.data.success){
  //       toast.success("Property Deleted",{
  //         duration:3000
  //       });
  //       window.location.reload(true);
  //     }
  //   }catch(err){
  //     console.log("delete err--",err);
  //     err.response?
  //     toast.error(err.response.data.message,{
  //       duration:5000
  //     })
  //     :
  //     toast.error('something went wrong',{
  //       duration:5000
  //     });
  //   }
  // }

  const addWishlist=async(listing_id)=>{
    try{
      if(currentUser){
        propertyDetails.wishlisted ? propertyDetails.wishlisted = 0 : propertyDetails.wishlisted = 1;
        reloadEffect();

        await addToWishlist(listing_id,currentUser.id);
        toast.success("Wishlist Updated",{
          duration:3000
        });
      }
      else{
        toast.error("You need to Login first",{
          duration:3000
        });
      }
    }catch(err){
      console.log("wishlist err--",err);
      err.response?
      toast.error(err.response.data.message,{
        duration:5000
      })
      :
      toast.error('something went wrong',{
        duration:5000
      });
    }
  }


  return (
    <>
      <div className='card-container'>
        <div className='card-img-sec'>
          <Link to={`/propertydetail/${propertyDetails.id}`}>
            <img src={propertyDetails && propertyDetails.images[0]} alt='img'/>
          </Link>
          <div onClick={()=>addWishlist(propertyDetails && propertyDetails.id)} className='wishlist-icon'>
            {
              propertyDetails.wishlisted?
              <AiFillHeart className='icon'/>
              : 
              <AiOutlineHeart className='icon'/>
            }
          </div>
          <div className='property-type-tag'>
            <p>{ propertyDetails && propertyDetails.property_type}</p>
          </div>
        </div>
        <div className='card-details-sec'>
          <div className='property-desc'>
            <Link to={`/propertydetail/${propertyDetails.id}`}>
              <h3>{ propertyDetails && propertyDetails.configuration + ' ' + propertyDetails.property_type + ' in ' + propertyDetails.locality}</h3>
            </Link>
            <p><span><MdLocationOn/> </span> {propertyDetails && propertyDetails.locality + ' ' + propertyDetails.city}</p>
          </div>
          <div className='property-price'>
            {
              propertyDetails && 
              propertyDetails.property_type === propertyTypesConstant.WEEKEND_HOME ?
                <h3><MdCurrencyRupee/>{propertyDetails && (new Intl.NumberFormat('en-IN').format(propertyDetails.rent))}<span>/day</span></h3>
                :
                <h3><MdCurrencyRupee/>{propertyDetails && (new Intl.NumberFormat('en-IN').format(propertyDetails.rent))}<span>/month</span></h3>
            }
            <p><FaWallet/><span className='rupee-icon'> <MdCurrencyRupee/></span>{propertyDetails && (new Intl.NumberFormat('en-IN').format(propertyDetails.deposit))}</p>
          </div>
          <div className='card-btn'>
          {/* {
            props.edit? 
            <div className='edit-del-btn'>
              <Link to={`/addlisting?edit=2`} state={propertyDetails}>
                <button className='edit-btn'><MdEdit/> Edit</button>
              </Link>
              <Link to={``}>
                <button onClick={handleDelete} className='del-btn'><MdDelete/> Delete</button>
              </Link>
            </div>
            :  */}
            <div className='view-btn-sec'>
              <Link to={`/propertydetail/${propertyDetails.id}`}>
                <button>View Details</button>
              </Link>
            </div>
          {/* } */}
          </div>
        </div>
        
      </div>
    </>
  )
}

export default Card;