import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";

import "./Login.css";
import { MdClose,MdRemoveRedEye } from "react-icons/md";
import toast from 'react-hot-toast';
import { forgetPassword, updatePassword } from "../../services/userService";
import LoadingSpinner from '../loadingSpinner/LoadingSpinner';


const verifyFields=()=>{
  let otp = document.getElementById('otp');
  let confirm_pass = document.getElementById('confirmPassword');
  let new_pass = document.getElementById('newPassword');
  if(otp.value.length<1){
    document.getElementById("otp").classList.add('field-alert');
    toast.error("Please Enter OTP",{
      duration:3000
    });
    return 0;
  }
  if(confirm_pass.value.length<1 || new_pass.value.length<1){
    document.getElementById("confirmPassword").classList.add('field-alert');
    document.getElementById("newPassword").classList.add('field-alert');
    toast.error("Please Enter Password",{
      duration:3000
    });
    return 0;
  }
  if(confirm_pass.value !== new_pass.value){
    document.getElementById("confirmPassword").classList.add('field-alert');
    document.getElementById("newPassword").classList.add('field-alert');
    document.getElementById("passMatchString").innerHTML="** Password Doesn't Match";
    toast.error("Password doesn't match",{
      duration:3000
    });
    return 0;
  }
  return 1;
}


const ResetPassword = () => {
  const [isLoading,setIsLoading] = useState(false);
  const [user,setUser] = useState({
    email:'',
    new_password:'',
    confirm_password:'',
    otp:''
  });
  const navigate = useNavigate();
  const [otpSend,setOtpSend] = useState(false);

  let name,value;
  const handleInput=(e)=>{
    e.preventDefault();
    name = e.target.name;
    value = e.target.value
    setUser({...user,[name]:value});

    if ( document.getElementById("otp").classList.contains('field-alert')){
      document.getElementById("otp").classList.remove('field-alert');
    }
    if ( document.getElementById("confirmPassword").classList.contains('field-alert')){
      document.getElementById("confirmPassword").classList.remove('field-alert');
    }
    if ( document.getElementById("newPassword").classList.contains('field-alert')){
      document.getElementById("newPassword").classList.remove('field-alert');
    }
    document.getElementById("passMatchString").innerHTML="";
  }

  //calling api
  const handleSendOtp= async (e)=>{
    e.preventDefault();
    try{
      setOtpSend(true)
      await forgetPassword(user.email);
    }
    catch(err){
      console.log(err.response.data.message);
      toast.error(err.response.data.message,{
        duration:5000
      });
    }
  }
  const handleSubmit= async (e)=>{
    e.preventDefault();
    try{
      let checkFields = verifyFields();
      if(checkFields){
        setIsLoading(true);
        await updatePassword(user);
        toast.success("Password Updated",{
          duration:2000
        });
        setIsLoading(false);
        navigate("/login");
      }
    }
    catch(err){
      console.log(err.response.data.message);
      setIsLoading(false);
      if(err.response.data.message === 'Incorrect OTP'){
        document.getElementById("otp").classList.add('field-alert');
      }
      toast.error(err.response.data.message,{
        duration:5000
      });
    }
  }

  const tooglePass = () =>{
    const passField = document.getElementById('confirmPassword');
    passField.type==='password'? passField.type='text' : passField.type='password';
  }

  return (
    <>
      <section className="login-container forget-pass-container">
        <div className="login-head">
          <h1>Reset Password</h1>
        </div>
        <div className="login-form reset-pass-form">
          <form className="forget-pass-form">
            <input name="email" onChange={handleInput} value={user.email} type="text" placeholder="Email"/>
            <button onClick={handleSendOtp}>Send OTP</button>
            {
              otpSend &&
              <span className="send-otp">Check Your Mail for OTP</span>
            }
          </form>
          <form className="reset-pass-form">
            <input id="otp" name="otp" disabled={!otpSend} onChange={handleInput} value={user.otp} type="number" placeholder="Enter OTP"/>
            <input id="newPassword" name="new_password" disabled={!otpSend} onChange={handleInput} value={user.new_password} type="password" placeholder="New Password"/>
            <div className="pass-toogle ">
              <input id="confirmPassword" disabled={!otpSend} className="pass-input" name="confirm_password" onChange={handleInput} value={user.confirm_password} type="password" placeholder="Confirm Password"/>
              <span className="eye-icon">
                <MdRemoveRedEye className="eye" onClick={tooglePass}/>
              </span>
            </div>
            <span id="passMatchString" className="password-match-string"></span>
            {
              isLoading? <LoadingSpinner/> : 
                          <button onClick={handleSubmit}>Reset Password</button>
            }
          </form>
        </div>

        {/* close btn */}
        <div className="close-con">
          <Link to={`/`}>
            <MdClose/>
          </Link>
        </div>
      </section>
    </>
  )
}

export default ResetPassword;