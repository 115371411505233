import { createContext, useState, useEffect } from "react";
import { userLogin, userLogout } from "../services/userService";

export const AuthContext = createContext();

export const AuthContextProvider = ({children}) =>{
  const [currentUser,setCurrentUser] = useState(JSON.parse(localStorage.getItem("user")) || null);
  const [currentLocation,setCurrentLocation]= useState(JSON.parse(localStorage.getItem("localLocation")) || currentUser && currentUser.location || 'Mumbai');
  const [locationEnabled,setLocationEnabled] = useState(false);
  const [searchFilters, setSearchFilters] = useState({});

  const changeLocation = (location) =>{
    setCurrentLocation(location);
    // setCurrentUser({...currentUser,location:location});
  }

  const login = async (user)=>{
      const res = await userLogin(user);
      setCurrentUser(res.data.user);
      setCurrentLocation(res.data.user.location)
  }

  const logout = async ()=>{
    await userLogout();
    setCurrentUser(null);
  }

  const locationVisibility=(visibility)=>{
    setLocationEnabled(visibility);
  }

  useEffect(()=>{
    localStorage.setItem("user",JSON.stringify(currentUser));
    localStorage.setItem("localLocation",JSON.stringify(currentLocation));
  },[currentUser,currentLocation]);

  return (
  <AuthContext.Provider value={{
    currentUser,
    login,
    logout,
    changeLocation,
    currentLocation,
    locationEnabled,
    locationVisibility,
    searchFilters,
    setSearchFilters,
    setCurrentUser
  }}>
    {children}
  </AuthContext.Provider>
  );
};