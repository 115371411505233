import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import '../MyProperties/index.css';
import Card2 from '../../components/card2/Card2.jsx';
import LoadingSpinner from '../../components/loadingSpinner/LoadingSpinner';
import { AuthContext } from '../../context/authContext';


const Terms = () => {
  const {locationVisibility} = useContext(AuthContext);

  const state = useLocation().state;
  const [properties,setProperties] = useState([]);
  const [heading,setHeading] = useState();
  const [isLoading, setIsLoading] = useState(false);


  useEffect(()=>{
    setIsLoading(true);
    setProperties(state.data);
    setHeading(state.head)
    setIsLoading(false);
  },[state])

  useEffect(()=>{
    const changeLocation=async(visibility)=>{
      try{
        await locationVisibility(visibility);
      }
      catch(err){
        console.log('error in enabling location visibility')
      }
    }
    changeLocation(false);
  })

  return (
    <>
      <div className='myproperties-page'>
        <div className='myproperties-page-head'>
          <h1>{heading}</h1>
        </div>
        {
          isLoading? <div className='home-loader'><LoadingSpinner/></div>
          :
          <>
          {
            properties?
            <div className='myproperties-page-content'>
              <div className='myproperties-cards'>
                {
                  properties.map((property)=>{
                    return <Card2 key={property.id} property={property}/>
                  })
                }
              </div>
            </div>
            :
            ''
          }
          </>
        }
      </div>
    </>
  )
}

export default Terms;