import React, { useContext, useEffect } from 'react'
import './index.css';
import {Helmet} from "react-helmet";
import { AuthContext } from '../../context/authContext';

const Privacy = () => {
  const { locationVisibility } = useContext(AuthContext)

  useEffect(()=>{
    const changeLocation=async(visibility)=>{
      try{
        await locationVisibility(visibility);
      }
      catch(err){
        console.log('error in enabling location visibility')
      }
    }
    changeLocation(false);
  },[])
return (
    <>
      <div className='terms-page'>
        <Helmet>
          <title>{"Privacy Policy"}</title>
          <meta name="description" content={"Tenant Network Privacy Policies"}/>
          <link rel='canonical' href='/privacypolicy'/>
        </Helmet>
        <div className='terms-page-head'>
          <h1>Privacy Policy</h1>
        </div>
        <div className='terms-page-content'>
          
          <p>This Privacy Policy governs the manner in which Tenant Network ("we," "us," or "our") collects, uses, maintains, and discloses information collected from users ("users" or "you") of the Tenant Network mobile application ("the App") available on Android and iOS platforms.</p>

          <h2>Information We Collect</h2>
          <h3>1.1 Personal Information</h3>
          <p>We may collect personal information from you when you voluntarily provide it to us during the registration process or through the use of the App. This may include your name, email address, phone number, and other relevant details.</p>

          <h3>1.2 Usage and Device Information</h3>
          <p>We may collect non-personal information about your use of the App, including but not limited to your device information, IP address, operating system, and browser type. This information is used to analyze trends, administer the App, and track user activities.</p>

          <h2>How We Use Collected Information</h2>
          <h3>2.1 Personal Information</h3>
          <p>We may use the personal information you provide for the following purposes:<br/></p>
          <p>To facilitate and improve your experience with the App</p>
          <p>To respond to your inquiries, comments, or feedback</p>
          <p>To send periodic emails regarding updates, promotions, or other relevant information</p>
          <p>To provide personalized advertisements and recommendations</p>
          <p>To process transactions or payments, if applicable</p>

          <h3>2.2 Non-Personal Information</h3>
          <p>Non-personal information collected may be used to analyze trends, monitor the usage of the App, and gather demographic information for marketing and advertising purposes.</p>

          <h2>Data Sharing and Disclosure</h2>
          <h3>3.1 Service Providers</h3>
          <p>We may engage third-party service providers to perform functions on our behalf, such as hosting, data analysis, marketing, and customer support. These service providers may have access to your personal information as necessary to perform their functions but are prohibited from using it for any other purpose.</p>

          <h3>3.2 Legal Compliance</h3>
          <p>We may disclose personal information if required to do so by law or in response to a valid request from a governmental authority, or to protect our rights, property, or safety or the rights, property, or safety of others.</p>

          <h2>Data Security</h2>
          <p>We take reasonable measures to protect the personal information collected through the App from unauthorized access, alteration, disclosure, or destruction. However, no method of transmission over the internet or electronic storage is completely secure, and we cannot guarantee absolute security.</p>

          <h2>Marketing and Third-Party Advertising</h2>
          <h3>5.1 Marketing Communications</h3>
          <p>By providing your contact information, you consent to receive marketing communications from us, including emails and push notifications. You can opt-out of these communications at any time by following the instructions provided in the communication or by contacting us directly.</p>

          <h3>5.2 Third-Party Advertising</h3>
          <p>We may display third-party advertisements within the App. These advertisements may use cookies and similar technologies to collect non-personal information about your activities on the App and other websites to provide targeted advertisements.</p>

          <h2>Changes to this Privacy Policy</h2>
          <p>We reserve the right to update this Privacy Policy at any time. We encourage you to review this policy periodically for any changes. Your continued use of the App after any modifications signifies your acceptance of the revised Privacy Policy.</p>

          <h2>Contact Us</h2>
          <p>If you have any questions or concerns about this Privacy Policy or the practices of the App, please contact us at [contact information].</p>

          <h2>Minimal Charge for Revealing Property Owner's Contact Details</h2>
          <p>8.1 We charge a nominal fee of INR 50 to reveal the contact details of property owners listed on our platform. This fee is solely for the purpose of maintaining and operating the Tenant Network platform.</p>

          <h2>Brokerage-Free Platform</h2>
          <p>9.1 Unlike traditional players in the market, we do not charge any brokerage fees from tenants seeking rental properties. Our aim is to provide a brokerage-free platform that directly connects tenants with property owners.</p>

          <h2>Property Search and Direct Communication</h2>
          <p>10.1 Tenant Network enables users to search for relevant properties based on their requirements. Users can directly schedule visits to the properties by accessing the contact information of property owners provided on our platform.</p>

          <h2>Flatmate Search and Co-tenants</h2>
          <p>11.1 In addition to property listings, Tenant Network also offers a platform for users to search for flatmates for shared accommodations. Users can find potential co-tenants to share a property with, facilitating collaborative living arrangements.</p>
          




        </div>
      </div>
    </>
  )
}

export default Privacy;