import React, {useState, useEffect, useContext} from 'react';
import { useParams, Link, useNavigate, useLocation } from 'react-router-dom';
import { MdAccountCircle, MdEditSquare, MdDelete } from "react-icons/md";
import moment from 'moment';
import toast from 'react-hot-toast';
import {Helmet} from "react-helmet";

import './index.css';
import { deleteBlogService, getBlogByTitle } from '../../services/blogService';
import { AuthContext } from '../../context/authContext';

const BlogPage = () => {
  const {currentUser, locationVisibility} = useContext(AuthContext);
  const navigate = useNavigate();
  const [isLoading,setIsLoading] = useState(false);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const blog_title = queryParams.get('blog_title');

  const [blogData,setBlogData] = useState();


  const deleteBlog = async()=>{
    if(window.confirm('Confirm to Delete:')){
      try{
          const delRes = await deleteBlogService(blogData.id, currentUser.id);
          if(delRes){
            toast.success("Blog Deleted",{
              duration:3000
            });
            navigate("/blogs");
          }
        }catch(err){
          console.log("delete err--",err);
          err.response?
          toast.error(err.response.data.message,{
            duration:5000
          })
          :
          toast.error('something went wrong',{
            duration:5000
          });
        }
      }
  }



  useEffect(()=>{
    const fetchBlog= async() =>{
      setIsLoading(true);
      try{
        const res = await getBlogByTitle(blog_title);
        setBlogData(res.data[0]);
        setIsLoading(false);
      }catch(err){
        window.alert("something went wrong");
        setIsLoading(false);
        console.log('getBlogDetails err---',err);
      }
    }

    blog_title && fetchBlog();
  },[blog_title]);
  
  useEffect(()=>{
    const changeLocation=async(visibility)=>{
      try{
        await locationVisibility(visibility);
      }
      catch(err){
        console.log('error in enabling location visibility')
      }
    }
    changeLocation(false);
  })


  return (
    <>
      <div className='blogpage-con'>
        <Helmet>
          <title>{blogData && blogData.title}</title>
          <meta name="description" content={blogData && blogData.summary}/>
          <link rel='canonical' href='/blog/:blog_title'/>
        </Helmet>
        <div className='blog-head'>
          <div className='blog-img'>
            <img src={blogData && blogData.image} alt='img'/>
          </div>
          <div className='head-content'>
            <div className='head-cat'>
              <span>
                { blogData && blogData.category}
              </span>
            </div>
            <div className='head-title'>
              <h1>
                {blogData && blogData.title}
              </h1>
            </div>
            <div className='head-author'>
              <span className='author-icon'>{<MdAccountCircle/>}</span>
              <p>{blogData && blogData.author_name}</p>
              {
                blogData &&
                <span>{moment(blogData.created_on).format("LL")}</span>
              }
              {
                (currentUser && blogData) && (currentUser.id === blogData.user_id || currentUser.isAdmin === 1) &&
                <>
                  <Link to={`/addblog?edit=2`} state={blogData}>
                    <MdEditSquare className='editblog-icon'/>
                  </Link>
                  <Link onClick={deleteBlog} className='deleteblog-icon'>
                    <MdDelete className='editblog-icon'/>
                  </Link>
                </>
              }
            </div>
          </div>
        </div>

        {/* blog content */}
        <div className='blogpage-page'>
          <div className='summary'>
            <p>{blogData && blogData.summary}</p>
          </div>

          {/* subhead */}
          {blogData && blogData.description.map((data,index)=>{
            if(index%2===0){
              return( 
                    <div className='subhead' key={index}>
                      <div className='subhead-content'>
                        <h2>{data.subheading}</h2>
                        <p>{data.description}</p>
                        <Link target='_blank' to={`${data.hyperlink}`}>{data.hyperlink}</Link>
                      </div>
                      <div className='subhead-img-col'>
                        <div className='subhead-image'>
                          <img src={data.image} alt='img' />
                        </div>
                      </div>
                    </div>
                )
            }
            else{
              return(
                    <div className='subhead subhead-odd' key={index}>
                      <div className='subhead-img-col'>
                        <div className='subhead-image'>
                          <img src={data.image} alt='img' />
                        </div>
                      </div>
                      <div className='subhead-content'>
                        <h2>{data.subheading}</h2>
                        <p>{data.description}</p>
                        <Link target='_blank' to={`${data.hyperlink}`}>{data.hyperlink}</Link>
                      </div>
                    </div>
                )
            }
          })}
        </div>
      </div>
    </>
  )
}

export default BlogPage;