import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { getSubscriptionDetails } from '../services/userService';
import { Divider, Typography } from '@mui/material';

function SubscriptionDialog({
  open,
  onClose,
  onSuccess,
  title,
  message,
}) {

  const [ subscriptionDetails, setSubscriptionDetails] = React.useState({});

  const handleSubmit = () => {
    onSuccess(subscriptionDetails.amount);
    onClose();
  }

  React.useEffect(()=>{
    const getListing= async() =>{
      try{
        const res = await getSubscriptionDetails();
        setSubscriptionDetails(res.data);
      }catch(err){
        console.log("fetch subscription details err--",err);
      }
    }

    getListing();
  },[open]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">
        {title}
      </DialogTitle>
      <Divider/>
      <DialogContent sx={{ minWidth: 380 }}>
        <DialogContentText style={{ display: 'flex', flexDirection: 'column', gap: '6px'}}>
          <Typography color='#000'>
            {message}
          </Typography>
          <Typography>
            Subscription Details: <span style={{color: '#000', fontWeight: '600'}}>{subscriptionDetails.days}-day access</span>
          </Typography>
          <Typography>
            Subscription Cost: <span style={{color: '#000', fontWeight: '600'}}>{subscriptionDetails.amount}/-</span>
          </Typography>
          <Typography>
            What's Included: All Access to our platform for <span style={{color: '#000', fontWeight: '600'}}>{subscriptionDetails.days} Days!</span>
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          variant={'outlined'}
          style={{color: '#000', borderColor: '#000'}}
          autoFocus
        >
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          variant={'contained'}
          style={{ backgroundColor: '#000'}}
          autoFocus
        >
          Subscribe
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default SubscriptionDialog;