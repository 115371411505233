import axios from "axios";
import { BASE_URL } from "./helper";

const getRazorpayOrder = async () => {
  const res = await axios.get(`${BASE_URL}/payments/getRazorpayDetails`,{withCredentials:true});
  return res;
}
const setRazorpayTransaction = async (data) => {
  const res = await axios.post(`${BASE_URL}/payments/setRazorpayTransaction`,data,{withCredentials:true});
  return res.data;
}

export {getRazorpayOrder, setRazorpayTransaction};