import React, { useContext, useEffect } from 'react'
import './index.css';
import {Helmet} from "react-helmet";
import { AuthContext } from '../../context/authContext';

const About = () => {
  const { locationVisibility } = useContext(AuthContext)

  useEffect(()=>{
    const changeLocation=async(visibility)=>{
      try{
        await locationVisibility(visibility);
      }
      catch(err){
        console.log('error in enabling location visibility')
      }
    }
    changeLocation(false);
  },[])
  return (
    <>
      <div className='terms-page about-page'>
        <Helmet>
          <title>{"About"}</title>
          <meta name="description" content={"About Tenant Network"}/>
          <link rel='canonical' href='/about'/>
        </Helmet>
        <div className='terms-page-head'>
          <h1>About Us</h1>
        </div>
        <div className='terms-page-content'>
          <p>
          At Tenant Network, we believe that finding the perfect rental property should be easy, convenient, and hassle-free. We understand the challenges faced by tenants when searching for a place to call home, whether it's in a new city or a familiar locality. That's why we've created a revolutionary platform that connects tenants directly with property owners, cutting out the middleman and eliminating costly brokerage fees.
          </p>
          <br/>

          <p>
          Our mission is simple: to provide a brokerage-free tech platform that empowers tenants to find their ideal rental properties with ease. We've built a comprehensive database of apartments, independent bungalows, hostels, and even weekend homes, offering a wide range of options to suit every need and budget.
          </p>
          <br/>

          <p>
          With Tenant Network, your rental search becomes a seamless experience. Our user-friendly platform allows you to search for properties based on your specific requirements, whether it's location, price, amenities, or property type. Once you've found a property that catches your interest, you can directly schedule a visit to the property by accessing the owner's contact details provided on our platform. No more waiting for brokers to arrange viewings or dealing with unnecessary intermediaries.
          </p>
          <br/>

          <p>
          n addition to facilitating direct communication between tenants and property owners, Tenant Network also offers a unique feature for those looking to share accommodations. Our platform enables users to search for flatmates and find co-tenants, making collaborative living arrangements easier than ever before.
          </p>
          <br/>

          <p>
          We take pride in creating a trusted community where tenants can find their perfect rental properties without the stress and uncertainty that often comes with traditional rental processes. Our commitment to privacy and data security ensures that your personal information is safeguarded at all times.
          </p>
          <br/>

          <p>
          Join Tenant Network today and embark on a new era of hassle-free renting. Let us help you find the home or weekend retreat of your dreams, where you can make lasting memories and build a comfortable life.
          </p>
          <br/>


          
        </div>
      </div>
    </>
  )
}

export default About;