import React from 'react';

import './index.css';
import LoginComponent from '../../components/login/Login';
import Welcome from '../../components/welcome/Welcome';

const Login = () => {

  return (
    <>
      <div className='login-page'>
        {/* welcome container */}
        {/* <section className='welcome-sec'>
          <div className='row1'>
            <img src={logo} alt='logo'/>
          </div>
          <div className='row2'>
            <div className='img-line-1'>
              <div className='img-1'>
                
              </div>
              <div className='img-2'>
                
              </div>
            </div>
            <div className='img-line-2'>
              <div className='img-2'>
                
              </div>
              <div className='img-1'>
                
              </div>
            </div>
          </div>
          <div className='row3'>
            <div className='headline-1'>
              <h3>Help you find the best place to stay</h3>
            </div>
            <div className='headline-2'>
              <p>Easy process, addordable prices and you can ger any units that you like anywhere, anytime</p>
            </div>
          </div>
        </section> */}
        <div className='welcome-con'>
          <Welcome/>
        </div>

        {/* login signup comp */}
        <section className='login-sec'>
          <LoginComponent/>      
        </section>
      </div>
    </>
  )
}

export default Login;