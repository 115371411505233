import React, { useContext, useState, useEffect } from 'react';
import { MdSearch, MdEast, MdAddCircle, MdOutlineChevronLeft, MdOutlineChevronRight } from "react-icons/md";
import { HiOutlineAdjustmentsHorizontal } from "react-icons/hi2";
import {Helmet} from "react-helmet";

import './index.css';
import '../Search/index.css';
import { Link} from 'react-router-dom';
import Card from '../../components/card/Card';
import { AuthContext } from '../../context/authContext';
import { getListings } from '../../services/listingService';
import LoadingSpinner from '../../components/loadingSpinner/LoadingSpinner';
import hero1 from '../../assets/hero1.png';
import hero2 from '../../assets/hero2.png';
import hero3 from '../../assets/homi.webp';

const handleLeftScroll=(id)=>{
  let box = document.getElementById(id);
  let width = box.clientWidth;
  box.scrollLeft = box.scrollLeft - width;
}
const handleRightScroll=(id)=>{
  let box = document.getElementById(id);
  let width = box.clientWidth;
  box.scrollLeft = box.scrollLeft + width;
}

const Home = () => {
  const {currentUser, currentLocation, locationVisibility} = useContext(AuthContext);
  const [recommendedProperties,setRecommendedProperties] = useState([]);
  const [bestDealsProperties,setBestDealsProperties] = useState([]);
  const [weekendHomes,setWeekendHomes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [search,setSearch] = useState();

  useEffect(()=>{
    const fetchProperties= async() =>{
      setIsLoading(true);
      try{
        const res = await getListings(currentLocation,currentUser? currentUser.id:'');
        setRecommendedProperties(res.data.recommended);
        setBestDealsProperties(res.data.best_deals);
        setWeekendHomes(res.data.weekend_home);

        setIsLoading(false);
      }catch(err){
        // window.alert("something went wrong");
        console.log('homepage err---',err);
        setIsLoading(false);
      }
    }

    fetchProperties();
  },[currentLocation]);

  useEffect(()=>{
    const changeLocation=async(visibility)=>{
      try{
        await locationVisibility(visibility);
      }
      catch(err){
        console.log('error in enabling location visibility')
      }
    }
    changeLocation(true);
  },[])

  return (
    <>
      <div className='home-page'>
        <Helmet>
          <title>{"Tenant Network"}</title>
          <meta name="description" content={"Welcome to Tenant Network Home Page"}/>
          <link rel='canonical' href='/home'/>
        </Helmet>
          <div className='main-content'>
            <div className='main-content-left'>
              <div className='left-content'>
                <h2>
                  A step towards a more democratic and user-centric property search experience
                </h2>
                <p>
                  Welcome to Tenant Network, a disruptive platform that redefines property hunting in India. 
                  By connecting seekers directly with property owners or co-tenants, Tenant Network eliminates the need for brokers and their hefty fees, 
                  making property search more transparent, affordable, and user-centric.
                </p>
              </div>
              <div className='left-content-btn'>
                {currentUser? 
                <Link to={'/addListing'}>
                  <button>
                    <span className='listing-btn-content'>
                      <h3>Add Listing</h3>
                      <span><MdAddCircle/></span>
                    </span>
                  </button>
                </Link>
                :
                <Link to={'/whatsappLogin'}>
                  <button>
                    Add Listing
                    <MdAddCircle/>
                  </button>
                </Link>
                }
              </div>
            </div>
  
            {/* right content */}
            <div className='main-content-right .welcome-sec'>
              <div className='right-content'>
                {/* <div className='img-col-1'>
                  <div className='image-1'>
                    
                  </div>
                  <div className='image-2'>
                    
                  </div>
                </div>
                <div className='img-col-2'>
                  <div className='image-2'>
                    
                  </div>
                  <div className='image-1'>
                    
                  </div>
                </div> */}
                {/* <img src={hero1} alt='hero1' />
                <img src={hero2} alt='hero2' /> */}
                <img src={hero3} alt='hero2' />
              </div>
            </div>
        </div>

        {/* greetings */}
        <div className='greeting-con'>
          <h4>Hello, {currentUser? currentUser.full_name: 'Guest'}</h4>
        </div>


        {/* home-page search */}
        <div className='homepage-search-con'>
          <div className='search-div'>
            <input onChange={(e)=>setSearch(e.target.value)} type='text' placeholder='Search House, Apartments etc.'/>
            <span className='search-icon'><MdSearch/></span>
            <span className='filter-icon'><Link to={`/search`} state={{popup:true}}><HiOutlineAdjustmentsHorizontal/></Link></span>
            <button><Link to={`/search`} state={{search}}>Search</Link></button>
          </div>
        </div>

        {/* property cards recommended section*/}
        {
          isLoading? <div className='home-loader'><LoadingSpinner/></div> :
          <>
          {recommendedProperties.length?
          <div className='cards-view-con'>
            <div className='cards-view-head'>
              <h3>Recommended Properties</h3>
              <h5><Link to={'/view?recommended_properties'} state={{data:recommendedProperties,head:'Recommended Properties'}}>View All</Link> </h5>
            </div>
            {/* cards */}
            <div className='cards-row' id='recomProp'>
              {
                recommendedProperties.map((property,index)=>{
                  if(index>4){
                    return;
                  }
                  return <Card key={property.id} property={property}/>
                })
              }
            </div>
            <div className='scroll-btn-sec'>
              <MdOutlineChevronLeft onClick={()=>handleLeftScroll('recomProp')} className='icon'/>
              <MdOutlineChevronRight onClick={()=>handleRightScroll('recomProp')} className='icon'/>
            </div>
          </div>
          : 
          ''
          }
          </>
        }

        {/* property cards best deals section*/}
        {
          isLoading? <div className='home-loader'><LoadingSpinner/></div> :
          <>
          {bestDealsProperties.length?
          <div className='cards-view-con'>
            <div className='cards-view-head'>
              <h3>Best Deals</h3>
              <h5><Link to={'/view?best_deals'} state={{data:bestDealsProperties,head:'Best Deals'}}>View All</Link> </h5>
            </div>
            {/* cards */}
            <div className='cards-row' id='bestDeals'>
              {
                bestDealsProperties.map((property,index)=>{
                  if(index>4){
                    return;
                  }
                  return <Card key={property.id} property={property}/>
                })
              }
            </div>
            <div className='scroll-btn-sec'>
              <MdOutlineChevronLeft onClick={()=>handleLeftScroll('bestDeals')} className='icon'/>
              <MdOutlineChevronRight onClick={()=>handleRightScroll('bestDeals')} className='icon'/>
            </div>
          </div>
          : 
          ''
          }
          </>
        }

        {/* home service */}
        <div className='home-service-con'>
          <div className='home-service-desc'>
            <h3>Home Service</h3>
            <p>We strive to provide an environment that fosters a high quality of living</p>
            <Link to={'/services'}>
              <button>Visit <MdEast/></button>
            </Link>
          </div>
        </div>

        {/* property cards weekend home section section*/}
        {
          isLoading? <div className='home-loader'><LoadingSpinner/></div> :
          <>
          {weekendHomes.length?
          <div className='cards-view-con'>
            <div className='cards-view-head'>
              <h3>Weekend Homes</h3>
              <h5><Link to={'/view?weekend_homes'} state={{data:weekendHomes,head:'Weekend Homes'}}>View All</Link> </h5>
            </div>
            {/* cards */}
            <div className='cards-row' id='bestDeals'>
              {
                weekendHomes.map((property,index)=>{
                  if(index>4){
                    return;
                  }
                  return <Card key={property.id} property={property}/>
                })
              }
            </div>
            <div className='scroll-btn-sec'>
              <MdOutlineChevronLeft onClick={()=>handleLeftScroll('bestDeals')} className='icon'/>
              <MdOutlineChevronRight onClick={()=>handleRightScroll('bestDeals')} className='icon'/>
            </div>
          </div>
          : 
          ''
          }
          </>
        }


      </div>
    </>
  )
}

export default Home