import React, { useContext, useState } from 'react'
import './index.css';
import { MdAddCircle, MdClose, MdCurrencyRupee } from "react-icons/md";
import toast from 'react-hot-toast';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Input } from '@chakra-ui/react';
import {Helmet} from "react-helmet";

import { AuthContext } from '../../context/authContext';
import cities from '../../data/cities.json';
import { addListing, updateListing } from '../../services/listingService';
import LoadingSpinner from "../../components/loadingSpinner/LoadingSpinner";
import { 
  propertyTypesConstant, furnishingStatusConstant, brokerageConstants, foodAllowedConstant, 
  tenantTypeConstant, listedByConstant, configurationConstant 
} from '../../constants/property';


const Listing = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { currentUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const state = useLocation().state;

  const [listingDetails, setListingDetail] = useState({
    listed_by: state?.listed_by || listedByConstant.OWNER,
    property_type: state?.property_type || propertyTypesConstant.APARTMENT,
    full_name: state?.full_name || (currentUser ? currentUser.full_name : ''),
    user_id: state?.user_id || (currentUser ? currentUser.id : ''),
    mobile_no: state?.mobile_no || (currentUser ? currentUser.mobile_no : ''),
    email: state?.email || (currentUser ? currentUser.email : ''),
    city: state?.city || (currentUser ? currentUser.location : ''),
    locality: state?.locality || '',
    rent: state?.rent || null,
    deposit: state?.deposit || 0,
    brokerage: state?.brokerage || brokerageConstants.ZERO
  });
  const [propertyDetails, setPropertyDetails] = useState({
    configuration: state?.configuration || configurationConstant._1BHK,
    tenant_type: state?.tenant_type || tenantTypeConstant.NO_PREFERENCE,
    food_allowed: state?.food_allowed || foodAllowedConstant.NO_PREFERENCE,
    furnishing_status: state?.furnishing_status || 'Fully Furnished',
    available_from: state?.available_from || new Date().toISOString().slice(0, 10)
  });
  const [localImages, setlocalImages] = useState(state?.images || []);
  const [images, setImages] = useState(state?.images || []);
  const [amenities, setAmenities] = useState(state?.amenities || []);
  const [buildinCategory, setBuildinCategory] = useState(state?.buildin_category || []);
  const [description, setDescription] = useState(state?.description || '');


  let name, value;
  const handleListingInput = (e) => {
    e.preventDefault();
    name = e.target.name;
    value = e.target.value;
    setListingDetail({ ...listingDetails, [name]: value });
  }
  const handlePropertyInput = (e) => {
    e.preventDefault();
    name = e.target.name;
    value = e.target.value;
    setPropertyDetails({ ...propertyDetails, [name]: value });
  }
  const handleAmenitiesInput = (e) => {
    if (!amenities.includes(e.target.value)) {
      setAmenities([...amenities, e.target.value]);
    }
    else {
      setAmenities(amenities.filter(item => item !== e.target.value));
    }
  }
  const handlebuildinCategoryInput = (e) => {
    if (!buildinCategory.includes(e.target.value)) {
      setBuildinCategory([...buildinCategory, e.target.value]);
    }
    else {
      setBuildinCategory(buildinCategory.filter(item => item !== e.target.value));
    }
  }

  const amenitiesField = (id, name, value) => {
    return (
      <div className='checkbox-wrap-row'>
        <input checked={amenities.includes(value)} onChange={handleAmenitiesInput} type="checkbox" id={id} name={name} value={value} />
        <label htmlFor={id}>{value}</label>
      </div>
    )
  }

  // img upload
  const handleUpload = (e) => {
    if (e.target.files && e.target.files[0]) {
      if (new RegExp("image/*").test(e.target.files[0].type)) {
        setlocalImages([...localImages, URL.createObjectURL(e.target.files[0])]);
        setImages([...images, e.target.files[0]]);
      }
      else {
        window.alert('Please select image format');
      }
    }
  }
  // img delete
  const handleRemoveImage = (delIndex) => {
    setlocalImages((imageArr) => imageArr.filter((img, index) => index !== delIndex));
    setImages((imageArr) => imageArr.filter((img, index) => index !== delIndex));
  }


  //calling api
  const postListing = async (e) => {
    setIsLoading(true);
    try {
      let updatedCurrentImg = [];

      const form = new FormData();
      for (let i = 0; i < images.length; i++) {
        if (images[i].name) {
          form.append('file', images[i]);
        }
        else {
          updatedCurrentImg.push(images[i]);
        }
      }

      state ?
        form.append('body', JSON.stringify({ id: state.id, ...listingDetails, ...propertyDetails, images: updatedCurrentImg, amenities, description, buildinCategory })) :
        form.append('body', JSON.stringify({ ...listingDetails, ...propertyDetails, amenities, description, buildinCategory }));

      const addListingResult = state ? await updateListing(form) : await addListing(form);

      setIsLoading(false);
      if (addListingResult.data.listed_id) {
        toast.success(addListingResult.data.message, {
          duration: 3000
        });
        navigate(`/propertydetail/${addListingResult.data.listed_id}`);
      }
      else {
        toast.error('something went wrong', {
          duration: 5000
        });
      }
    }
    catch (err) {
      setIsLoading(false);
      err.response ?
        toast.error(err.response.data.message, {
          duration: 5000
        })
        :
        toast.error('something went wrong', {
          duration: 5000
        });
    }
  }


  return (
    <>
      <div className='listing-page'>
        <Helmet>
          <title>{"Add Property"}</title>
          <meta name="description" content={"Users Can list their Property here"}/>
          <link rel='canonical' href='/addlisting'/>
        </Helmet>
        <div className='listing'>
          <div className='listing-page-left'>
            {/* -----listing details------ */}
            <div className='listing-details'>
              <div className='listing-details-head'>
                <h1>Listing Details</h1>
              </div>
              <div className='listing-details-form'>
                <form>
                  <select value={listingDetails.listed_by || ''} name='listed_by' onChange={handleListingInput}>
                    <option value={listedByConstant.OWNER}>{listedByConstant.OWNER}</option>
                    <option value={listedByConstant.BROKER}>{listedByConstant.BROKER}</option>
                    <option value={listedByConstant.TENANT}>{listedByConstant.TENANT}</option>
                    <option value={listedByConstant.FLATMATE}>{listedByConstant.FLATMATE}</option>
                  </select>
                  <select value={listingDetails.property_type || ''} name='property_type' onChange={handleListingInput}>
                    <option value={propertyTypesConstant.APARTMENT}>{propertyTypesConstant.APARTMENT}</option>
                    <option value={propertyTypesConstant.WEEKEND_HOME}>{propertyTypesConstant.WEEKEND_HOME}</option>
                    <option value={propertyTypesConstant.PG_HOSTEL}>{propertyTypesConstant.PG_HOSTEL}</option>
                  </select>

                  <input onChange={handleListingInput} value={listingDetails.full_name || ''} type='text' name='full_name' placeholder='Full Name' />
                  <input onChange={handleListingInput} value={listingDetails.mobile_no || ''} type='text' name='mobile_no' placeholder='Mobile Number' />
                  <input onChange={handleListingInput} value={listingDetails.email || ''} type='text' name='email' placeholder='Email' />

                  <select value={listingDetails.city || ''} name='city' onChange={handleListingInput}>
                    <option value={''}>choose city</option>
                    {cities.map((city) => {
                      return <option key={city.id} value={city.name}>{city.name}</option>
                    })}
                  </select>

                  <input onChange={handleListingInput} value={listingDetails.locality || ''} type='text' name='locality' placeholder='Locality' />

                  <div className='input-div'>
                    <span><MdCurrencyRupee className='icon' /></span>
                    <input onChange={handleListingInput} value={listingDetails.rent || ''} name='rent' type='number' placeholder='Rent' />
                  </div>
                  <div className='input-div'>
                    <span><MdCurrencyRupee className='icon' /></span>
                    <input onChange={handleListingInput} value={listingDetails.deposit || ''} name='deposit' type='number' placeholder='Deposit' />
                  </div>
                  {
                    (listingDetails.property_type === '' || listingDetails.property_type === propertyTypesConstant.APARTMENT || listingDetails.property_type === propertyTypesConstant.PG_HOSTEL) &&
                    <div className='field'>
                      <label htmlFor='brokerage'>Brokerage-</label>
                      <select value={listingDetails.brokerage || ''} onChange={handleListingInput} name='brokerage' id='brokerage'>
                        <option value={brokerageConstants.ZERO}>{brokerageConstants.ZERO}</option>
                        <option value={brokerageConstants._15_DAYS_RENT}>{brokerageConstants._15_DAYS_RENT}</option>
                        <option value={brokerageConstants._30_DAYS_RENT}>{brokerageConstants._30_DAYS_RENT}</option>
                      </select>
                    </div>
                  }
                </form>
              </div>
            </div>

            {/* -----property details------ */}
            <div className='listing-details property details'>
              <div className='listing-details-head'>
                <h1>Property Details</h1>
              </div>
              <div className='listing-details-form'>
                <form>
                  {
                    listingDetails.property_type === propertyTypesConstant.PG_HOSTEL ?
                      <div className='field'>
                        <label htmlFor='totalRooms'>Configuration</label>
                        <select value={propertyDetails.configuration ? propertyDetails.configuration : configurationConstant.SINGLE_SHARING} onChange={handlePropertyInput} name='configuration' id='totalRooms'>
                          <option value={configurationConstant.SINGLE_SHARING}>{configurationConstant.SINGLE_SHARING}</option>
                          <option value={configurationConstant.DOUBLE_SHARING}>{configurationConstant.DOUBLE_SHARING}</option>
                          <option value={configurationConstant.TRIPLE_SHARING}>{configurationConstant.TRIPLE_SHARING}</option>
                          <option value={configurationConstant.FOUR_SHARING}>{configurationConstant.FOUR_SHARING}</option>
                        </select>
                      </div>
                      :
                      <div className='field'>
                        <label htmlFor='totalRooms'>Configuration</label>
                        <select value={propertyDetails.configuration ? propertyDetails.configuration : configurationConstant._1BHK} onChange={handlePropertyInput} name='configuration' id='totalRooms'>
                          <option value={configurationConstant._1BHK}>{configurationConstant._1BHK}</option>
                          <option value={configurationConstant._2BHK}>{configurationConstant._2BHK}</option>
                          <option value={configurationConstant._3BHK}>{configurationConstant._3BHK}</option>
                          <option value={configurationConstant._4BHK}>{configurationConstant._4BHK}</option>
                          <option value={configurationConstant._5BHK}>{configurationConstant._5BHK}</option>
                          <option value={configurationConstant._6BHK}>{configurationConstant._6BHK}</option>
                        </select>
                      </div>
                  }
                  <div className='field'>
                    <label htmlFor='tenantType'>Tenant Type</label>
                    <select value={propertyDetails.tenant_type || ''} onChange={handlePropertyInput} name='tenant_type' id='tenantType'>
                      {
                        (listingDetails.property_type === propertyTypesConstant.APARTMENT || listingDetails.property_type === '') &&
                        <>
                          <option value={tenantTypeConstant.NO_PREFERENCE}>{tenantTypeConstant.NO_PREFERENCE}</option>
                          <option value={tenantTypeConstant.FAMILY}>{tenantTypeConstant.FAMILY}</option>
                          <option value={tenantTypeConstant.MALE}>{tenantTypeConstant.MALE}</option>
                          <option value={tenantTypeConstant.FEMALE}>{tenantTypeConstant.FEMALE}</option>
                        </>
                      }
                      {
                        listingDetails.property_type === propertyTypesConstant.PG_HOSTEL &&
                        <>
                          <option value={tenantTypeConstant.NO_PREFERENCE}>{tenantTypeConstant.NO_PREFERENCE}</option>
                          <option value={tenantTypeConstant.MALE}>{tenantTypeConstant.MALE}</option>
                          <option value={tenantTypeConstant.FEMALE}>{tenantTypeConstant.FEMALE}</option>
                        </>
                      }
                      {
                        listingDetails.property_type === propertyTypesConstant.WEEKEND_HOME &&
                        <>
                        <option value={tenantTypeConstant.FAMILY}>{tenantTypeConstant.FAMILY}</option>
                        <option value={tenantTypeConstant.BACHELOR}>{tenantTypeConstant.BACHELOR}</option>
                        </>
                      }
                    </select>
                  </div>

                  <div className='field'>
                    <label htmlFor='foodAllowed'>Food Allowed</label>
                    <select value={propertyDetails.food_allowed || ''} onChange={handlePropertyInput} name='food_allowed' id='foodAllowed'>
                      <option value={foodAllowedConstant.NO_PREFERENCE}>{foodAllowedConstant.NO_PREFERENCE}</option>
                      <option value={foodAllowedConstant.VEG}>{foodAllowedConstant.VEG}</option>
                      <option value={foodAllowedConstant.NONVEG}>{foodAllowedConstant.NONVEG}</option>
                    </select>
                  </div>
                  <div className='field'>
                    <label htmlFor='furnishingStatus'>Furnishing Status</label>
                    <select value={propertyDetails.furnishing_status || ''} onChange={handlePropertyInput} name='furnishing_status' id='furnishingStatus'>
                      <option value={furnishingStatusConstant.FULLY_FURNISHED}>{furnishingStatusConstant.FULLY_FURNISHED}</option>
                      <option value={furnishingStatusConstant.SEMI_FURNISHED}>{furnishingStatusConstant.SEMI_FURNISHED}</option>
                      <option value={furnishingStatusConstant.UNFURNISHED}>{furnishingStatusConstant.UNFURNISHED}</option>
                    </select>
                  </div>
                  <div className='field'>
                    <label htmlFor='availableFrom'>Available From</label>
                    <input type='text' placeholder='Select Date' onBlur={(e) => (e.target.type = "text")} onFocus={(e) => (e.target.type = "date")} value={propertyDetails.available_from} onChange={handlePropertyInput} name='available_from' id='availableFrom' />
                  </div>
                </form>
              </div>
            </div>
          </div>

          {/* right */}
          <div className='listing-page-right'>
            {/* image listing */}
            <div className='listing-details'>
              <div className='listing-details-head'>
                <h1>Add Images</h1>
              </div>
              <div className='listing-details-data'>
                <div className='listing-images'>
                  <Input disabled={localImages.length >= 10} id="imageInput" type="file" hidden onChange={handleUpload} />
                  <Button
                    disabled={localImages.length >= 10}
                    className='chakra-btn'
                    as="label"
                    htmlFor="imageInput"
                  >
                    <MdAddCircle className='icon' />
                  </Button>
                  {localImages && localImages.map((img, index) => {
                    return (
                      <div key={index} className='img-div'>
                        <img id={index} src={img} alt='img' />
                        <span onClick={() => handleRemoveImage(index)} className="img-remove-btn">
                          <MdClose id={index} className='remove-btn' />
                        </span>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>

            {/* amenities */}
            <div className='listing-details'>
              <div className='listing-details-head'>
                <h1>Amenities Available</h1>
              </div>
              <div className='listing-details-data'>
                <div className='checkboxes'>
                  {/* checkbox left sec */}
                  <div className='checkbox-wrap checkbox-left'>
                    {amenitiesField('wifiAvailable', 'wifi_available', 'Wifi Available')}
                    {amenitiesField('swimmingPool', 'swimming_pool', 'Swimming Pool')}
                    {amenitiesField('lift', 'lift', 'Lift')}
                    {amenitiesField('gym', 'gym', 'Gym')}
                    {amenitiesField('gasPipeline', 'gas_pipeline', 'Gas Pipeline')}
                    {amenitiesField('clubHouse', 'club_house', 'Club House')}
                    {amenitiesField('caretaker', 'caretaker', 'Caretaker')}
                    {amenitiesField('spa', 'spa', 'Spa')}
                    {amenitiesField('joggersTrack', 'joggers_track', 'Joggers Track')}
                    {amenitiesField('theatre', 'theatre', 'Theatre')}
                    {amenitiesField('meditationZone', 'meditation_zone', 'Meditation Zone')}
                    {amenitiesField('medicalStore', 'medicalStore', 'Medical Store')}
                  </div>
                  {/* checkbox right sec */}
                  <div className='checkbox-wrap checkbox-right'>
                    {amenitiesField('powerBackup', 'power_backup', 'Power Backup')}
                    {amenitiesField('security', 'security', 'Security')}
                    {amenitiesField('gatedCommunity', 'gated_community', 'Gated Community')}
                    {amenitiesField('cctv', 'cctv', 'CCTV')}
                    {amenitiesField('parking', 'parking', 'Parking')}
                    {amenitiesField('indoorGames', 'indoor_games', 'Indoor Games')}
                    {amenitiesField('outdoorGames', 'outdoor_games', 'Outdoor Games')}
                    {amenitiesField('garden', 'garden', 'Garden')}
                    {amenitiesField('banquet', 'banquet', 'Banquet')}
                    {amenitiesField('departmentStore', 'departmental_store', 'Departmental Store')}
                    {amenitiesField('laundryFacility', 'laundry_facility', 'Laundry Facility')}
                  </div>
                </div>
              </div>
            </div>

            {/* admin category */}
            {
              currentUser && currentUser.isAdmin ?
                <div className='listing-details'>
                  <div className='listing-details-head'>
                    <h1>Build-in Category</h1>
                  </div>
                  <div className='listing-details-data'>
                    <div className='checkboxes'>
                      {/* checkbox left sec */}
                      <div className='checkbox-wrap checkbox-left'>
                        <div className='checkbox-wrap-row'>
                          <input checked={buildinCategory.includes('Recommended')} onChange={handlebuildinCategoryInput} type="checkbox" id='recommended' name='recommended' value={'Recommended'} />
                          <label htmlFor='recommended'>Recommended</label>
                        </div>

                        <div className='checkbox-wrap-row'>
                          <input checked={buildinCategory.includes('Weekend Home')} onChange={handlebuildinCategoryInput} type="checkbox" id='weekendHome' name='weekend_home' value={'Weekend Home'} />
                          <label htmlFor='weekendHome'>Weekend Home</label>
                        </div>
                      </div>
                      {/* checkbox right sec */}
                      <div className='checkbox-wrap checkbox-right'>
                        <div className='checkbox-wrap-row'>
                          <input checked={buildinCategory.includes('Best Deals')} onChange={handlebuildinCategoryInput} type="checkbox" id='bestDeals' name='best_deals' value={'Best Deals'} />
                          <label htmlFor='bestDeals'>Best Deals</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                :
                ""
            }

            {/* description */}
            <div className='listing-details'>
              <div className='listing-details-head'>
                <h1>Description</h1>
              </div>
              <div className='listing-details-data'>
                <div className='text-area'>
                  <textarea onChange={(e) => setDescription(e.target.value)} value={description} />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* listing submit btn */}
        <div className='listing-btn'>
          {
            isLoading ? <LoadingSpinner /> : <button onClick={postListing} disabled={isLoading}>Submit</button>
          }
        </div>
      </div>
    </>
  )
}

export default Listing;