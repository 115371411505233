import React, { useContext, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { Link, useNavigate } from 'react-router-dom';
import { MdAccountCircle, MdAddCircle, MdLocationOn,MdMenu } from "react-icons/md";

import './Header.css';
import logo from "../../assets/LOGO.png";
import { AuthContext } from '../../context/authContext';
import cities from "../../data/cities.json";

const handleMenu=()=>{
  if ( document.getElementById("showMenu").classList.contains('toogle-menu-show')){
    document.getElementById("showMenu").classList.remove('toogle-menu-show');
  }
  else{
    document.getElementById("showMenu").classList.add('toogle-menu-show');
  }
}

const Header = () => {
  // const [navExpand,setNavExpand] = useState('');
  const {currentUser,logout,changeLocation, currentLocation, locationEnabled} = useContext(AuthContext);
  const navigate = useNavigate();
  const [activeLink,setActiveLink] = useState('/'); 
  let pathname = window.location.pathname;

  const handleLogout=async()=>{
    try{
      await logout();
      toast.success("logout success",{
        duration:2000
      });
      navigate("/whatsappLogin");
    }
    catch(err){
      console.log('logout error---',err.response.data);
      toast.error("error in logging out",{
        duration:5000
      });
    }
  }

  const handleLocation=async(e)=>{
    try{
      await changeLocation(e.target.value);

    }catch(err){
      console.log(err.response.data);
      toast.error("location not fetched",{
        duration:5000
      });
    }
  }

  useEffect(()=>{
    setActiveLink(pathname);
  },[pathname])


  return (
    <>
      <header className='navbar'>
        <div className='nav-left'>
          <div className='logo'>
            <Link to={'/'}>
              <img src={logo} alt='logo'/>
            </Link>
          </div>
          {
            locationEnabled &&
            <div className='location'>
              <div className='loc-icon'>
                <MdLocationOn/>
              </div>
              <span>
                <select value={currentLocation? currentLocation : ''} onChange={handleLocation}  name='city' >
                  {cities.map((city)=>{
                    return <option key={city.id} value={city.name}>{city.name}</option>
                  })}
                </select>
              </span>
            </div>
          }
        </div>
        <div className='nav-right'>
          <nav className='nav-menu'>
            <ul>
              <li><Link to='/about' className={activeLink==='/about'? 'activePage' : ''}>About</Link></li>
              <li><Link to='/services' className={activeLink==='/services'? 'activePage' : ''}>Services</Link></li>
              <li><Link to='/wishlist' className={activeLink==='/wishlist'? 'activePage' : ''}>Wishlist</Link></li>
              <li><Link to='/properties' className={activeLink==='/properties'? 'activePage' : ''}>Properties</Link></li>
              <li><Link to='/blogs' className={activeLink==='/blogs'? 'activePage' : ''}>Blogs</Link></li>
            </ul>
          </nav>
          <div className='nav-listing'>
          {currentUser? 
            <Link to='/addlisting'> Add Listing <MdAddCircle/> </Link> :
            <Link to='/whatsappLogin'> Add Listing <MdAddCircle/> </Link> 
          }
          </div>
          <div className='nav-profile'>
          {
            currentUser? 
          
              <div className='profile-dropdown'>
                <div className='profile-dropdown-btn'>
                  <Link to={'/profile'}>
                    <MdAccountCircle className='profile-icon'/> 
                    {currentUser.full_name}
                  </Link>
                </div>
                {/* <div className='profile-dropdown-content'>
                  <Link to='/profile'>My Profile</Link>
                  <Link to='#'>WishList</Link>
                  <Link to='#'>Visits</Link>
                  <Link to='/myproperties'>Listed Properties</Link>
                  <Link to='#'>About</Link>
                  <Link to='/terms&conditions'>Terms and Conditions</Link>
                  <Link to='/privacypolicy'>Privacy Policy</Link>
                  {
                    currentUser && <Link onClick={handleLogout}>Logout</Link>
                  }
                </div> */}
              </div>
              :
              <div className='profile-login'>
                <Link to='/whatsappLogin'>
                  Login/Signup
                </Link>
              </div>
              }
          </div>
        </div>
        <div className='hamburger'>
          <MdMenu onClick={handleMenu}/>
        </div>
      </header>

      {/* full screen menu toogle */}
      <div className='toogle-menu' id='showMenu'>
        <div className='toggle-menu-expand'>
          <ul>
            <li><Link onClick={handleMenu} to='/'>Home</Link></li>
            {currentUser? <li><Link onClick={handleMenu} to='/profile'>My Profile</Link></li>: <li><Link onClick={handleMenu} to='/whatsappLogin'>My Profile</Link></li>}
            {currentUser? <li className='addlisting-menu'><Link onClick={handleMenu} to='/addlisting'><button>Add Listing <MdAddCircle/></button></Link></li>: <li><Link onClick={handleMenu} to='/whatsappLogin'><button>Add Listing <MdAddCircle/></button></Link></li>}
            
            <li><Link onClick={handleMenu} to='/properties'>Properties</Link></li>
            <li><Link onClick={handleMenu} to='/about'>About</Link></li>
            <li><Link onClick={handleMenu} to='/services'>Service</Link></li>
            <li><Link onClick={handleMenu} to='/wishlist'>Wishlist</Link></li>
            <li><Link onClick={handleMenu} to='/blogs'>Blogs</Link></li>
            { currentUser? <li><Link onClick={handleLogout}>Logout</Link></li> : <li><Link to={'/whatsappLogin'}>Login</Link></li> }
          </ul>
        </div>
      </div>
    </>
  )
}

export default Header;