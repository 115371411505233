import * as React from 'react'
import ContactInfo from '../components/contactInfo/ContactInfo'
import { getListingContactDetails } from '../services/listingService';
import toast from 'react-hot-toast';

const ContactInfoDialog = ({
  popup, setPopup, property, addToVisits, 
}) => {
  const [open, setOpen] = React.useState(false);
  const [contantDetails, setContactDetails] = React.useState({});

  const getDetails= async() =>{
    try{
      const res = await getListingContactDetails(property.id);
      if(res.listingContactDetails?.length){
        setContactDetails(res.listingContactDetails?.[0]);
        setOpen(true);
      }
    }catch(err){
      console.log("fetch listing contact details err--",err);
      toast.error(err.response.data.message,{
        duration:5000
      });
      setPopup(false);
      setOpen(false);
    }
  }

  const handleClose = () => {
    setPopup(false);
    setOpen(false);
  }

  React.useEffect(()=>{
    if(!popup) return;
    if(property?.isVisited){
      getDetails();
    }
    else{
      addToVisits();
      getDetails();
    }
  },[popup, property])

  return (
    <ContactInfo className='filter-pop' trigger={open} setTrigger={handleClose}>
        <div className='contact-popup-info'>
          <div className='contact-data'>
            <ul>
              <li>Owner Name:</li>
              <li>Owner Number:</li>
              <li>Owner Email:</li>
            </ul>
            <ul>
              <li>{contantDetails.full_name}</li>
              <li>{contantDetails.mobile_no}</li>
              <li>{contantDetails.email}</li>
            </ul>
          </div>
        </div>
      </ContactInfo>
  )
}

export default ContactInfoDialog