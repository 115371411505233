import React,{useContext} from 'react';
import { Link } from 'react-router-dom';
import { FaGoogle, FaTwitter, FaInstagram, FaLinkedin, FaFacebook } from "react-icons/fa";
import { MdEmail, MdPhone } from "react-icons/md";

import './Footer.css';
import { AuthContext } from '../../context/authContext';


const Footer = () => {
  const {currentUser} = useContext(AuthContext);

  return (
    <>
      <div className='footer'>
        <div className='footer-left'>
          <div className='footer-left-content'>
            <div className='footer-left-head'>
              <h2>Tenant Network</h2>
            </div>
            <div className='footer-left-text'>
              <p>Step Into a World Where Property Search Is Simplified. Tenant Network - Your Gateway to Direct, Hassle-Free Listings.</p>
            </div>
            <div className='footer-left-social'>
              <span><Link to={'https://www.facebook.com/profile.php?id=61550761089579'} target='_blank'><FaFacebook/></Link></span>
              <span><Link to={'https://www.instagram.com/tenantnetwork.in/'} target='_blank'><FaInstagram/></Link></span>
              <span><Link to={'https://www.linkedin.com/company/tenantnetwork/'} target='_blank'><FaLinkedin/></Link></span>
            </div>
          </div>
        </div>
        <div className='footer-right'>
          <div className='col col-1'>
            <h3>Company</h3>
            <ul>
              <li><Link to={'/about'}>About</Link></li>
              <li><Link to={'/terms&conditions'}>Terms & Conditions</Link></li>
              <li><Link to={'/privacypolicy'}>Privacy Policy</Link></li>
            </ul>            
          </div>
          <div className='col col-2'>
            <h3>Pages</h3>
            <div className='inner-col'>
              <ul>
                {currentUser? 
                  <>
                    <li><Link to={'/addlisting'}>Add Listing</Link></li> 
                    <li><Link to={'/wishlist'}>Wishlist</Link></li>
                    <li><Link to={'/visits'}>My Visits</Link></li>
                    <li><Link to={'/blogs'}>Blogs</Link></li>
                  </>
                  :
                  <>
                    <li><Link to={'/whatsappLogin'}>Add Listing</Link></li>
                    <li><Link to={'/whatsappLogin'}>Wishlist</Link></li>
                    <li><Link to={'/whatsappLogin'}>My Visits</Link></li>
                    <li><Link to={'/blogs'}>Blogs</Link></li>
                  </>
                }
              </ul>
              <ul>
                <li><Link to={'/services'}>Services</Link></li>
                {
                  currentUser? 
                  <>
                    <li><Link to={'/profile'}>Profile</Link></li> 
                    <li><Link to={'/myproperties'}>My Properties</Link></li>
                    <li><Link to={'/properties'}>All Properties</Link></li>
                  </>
                  :
                  <>
                    <li><Link to={'/whatsappLogin'}>Profile</Link></li>
                    <li><Link to={'/whatsappLogin'}>My Properties</Link></li>
                    <li><Link to={'/properties'}>All Properties</Link></li>
                  </>
                }

              </ul>
            </div>
          </div>
        </div>

        {/* floating contact bar */}
        <div className='contact-bar-div'>
          <div className='contact-bar'>
            <div className='contact-bar-content'>
              <span className='icon'><MdEmail/></span>
              <span>contact@tenantnetwork.in</span>
            </div>
            <div className='contact-bar-content'>
              <span className='icon'><MdPhone/></span>
              <span>+91 916 762 7776</span>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Footer