import React from 'react';

import './index.css';
import SignupComponent from '../../components/login/Signup';
import Welcome from '../../components/welcome/Welcome';

const Signup = () => {

  return (
    <>
      <div className='login-page'>
        {/* welcome container */}
        <div className='welcome-con'>
          <Welcome/>
        </div>

        {/* login signup comp */}
        <section className='login-sec'>
          <SignupComponent/>      
        </section>
      </div>
    </>
  )
}

export default Signup;