import React from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { MdOutlineChevronLeft, MdOutlineChevronRight } from "react-icons/md";

import './Carousel.css';


const handleLeftScroll=(id)=>{
  let box = document.getElementById(id);
  let width = box.clientWidth;
  box.scrollLeft = box.scrollLeft - width;
}
const handleRightScroll=(id)=>{
  let box = document.getElementById(id);
  let width = box.clientWidth;
  box.scrollLeft = box.scrollLeft + width;
}

const CarouselComponent = (props) => {
  const imagesArray = props.images? props.images : [];
  
  return (
    <>
      <div className='carousel'>
        <div className='carousel-images' id='carouselImg'>
          {imagesArray.map((images,index)=>{
            return <div key={images} className='img-div'>
            <img src={images} alt='property_img'/>
            </div>
          })}
        </div>
        <div onClick={()=>handleLeftScroll('carouselImg')} className='scroll-btns'>
          <MdOutlineChevronLeft className='scroll-icon'/>
        </div>
        <div onClick={()=>handleRightScroll('carouselImg')} className='scroll-btns right-scroll'>
          <MdOutlineChevronRight className='scroll-icon' />
        </div>
      </div>

    </>
  )
}

export default CarouselComponent