import React from 'react';
import { MdClose } from "react-icons/md";

import './ContactInfo.css';

const ContactInfo = (props) => {
  return (props.trigger) ? (
    <div className={(props.head)? 'contact-popup contact-popup-new':'contact-popup'} id='contactPopup'>
      <div className='contact-body'>
        <button onClick={()=>props.setTrigger(false)} className='close-btn'>
          <MdClose className='icon'/>
        </button>
        <div className='contact-head'>
          <h1>{props.head? props.head :  'Owner Details'}</h1>
        </div>
        {props.children} 
      </div>
    </div>
  )
  : 
  ""
}

export default ContactInfo