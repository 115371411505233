import React, { useContext, useEffect } from 'react'
import './index.css';
import {Helmet} from "react-helmet";
import { AuthContext } from '../../context/authContext';

const Terms = () => {
  const { locationVisibility } = useContext(AuthContext)

  useEffect(()=>{
    const changeLocation=async(visibility)=>{
      try{
        await locationVisibility(visibility);
      }
      catch(err){
        console.log('error in enabling location visibility')
      }
    }
    changeLocation(false);
  },[])
  return (
    <>
      <div className='terms-page'>
        <Helmet>
          <title>{"Terms and Conditions"}</title>
          <meta name="description" content={"Our Terms and Conditions"}/>
          <link rel='canonical' href='/terms&conditions'/>
        </Helmet>
        <div className='terms-page-head'>
          <h1>Terms and conditions</h1>
        </div>
        <div className='terms-page-content'>
          <p>
            Please read these Terms and Conditions ("Agreement") carefully before accessing or using the Tenant Network website ("Website") or mobile application ("App") operated by Tenant Network ("we," "us," or "our").
          </p>
          <p>
            By accessing or using the Website or App, you agree to be bound by this Agreement. If you do not agree to all the terms and conditions of this Agreement, you may not access or use the Website or App.
          </p>

          <h2>Use of the Website and App</h2>
          <h3>1.1 Eligibility</h3>
          <p>You must be at least 18 years old and have the legal capacity to enter into a contract to use the Website and App. By accessing or using the Website or App, you represent and warrant that you meet these eligibility requirements.</p>


          <h3>1.2 User Account</h3>
          <p>You may be required to create a user account to access certain features of the Website or App. When creating your account, you must provide accurate and complete information. You are responsible for maintaining the confidentiality of your account credentials and are fully responsible for all activities that occur under your account.</p>


          <h3>1.3 Prohibited Activities</h3>
          <p>You agree not to engage in any activities that may:<br/></p>

          <p>Violate any applicable laws or regulations</p>
          <p>Infringe upon the intellectual property or other rights of third parties</p>
          <p>Interfere with or disrupt the operation of the Website or App</p>
          <p>Attempt to gain unauthorized access to the Website or App, or any related systems or networks</p>
          

          <h2>Intellectual Property Rights</h2>

          <h3>2.1 Ownership</h3>
          <p>The Website, App, and all content, features, and functionality are owned by Tenant Network and are protected by intellectual property laws. You acknowledge and agree that all right, title, and interest in and to the Website, App, and content belong to Tenant Network.</p>

          <h3>2.2 Limited License</h3>
          <p>Subject to your compliance with this Agreement, we grant you a limited, non-exclusive, non-transferable license to access and use the Website and App for personal, non-commercial purposes. This license does not permit you to modify, distribute, reproduce, or create derivative works of the Website or App.</p>




          <h2>Privacy</h2>
          <p>Your privacy is important to us. Please review our Privacy Policy [include hyperlink to the Privacy Policy] to understand how we collect, use, and disclose information obtained through the Website and App.</p>

          <h2>Limitation of Liability</h2>
          <p>To the fullest extent permitted by applicable law, Tenant Network and its officers, directors, employees, or agents shall not be liable for any direct, indirect, incidental, special, consequential, or exemplary damages, including but not limited to damages for loss of profits, goodwill, use, data, or other intangible losses, resulting from your use or inability to use the Website or App.</p>

          <h2>Modifications to the Agreement</h2>
          <p>We reserve the right, at our sole discretion, to modify or replace this Agreement at any time. The most current version of the Agreement will be posted on the Website. It is your responsibility to review this Agreement periodically. By continuing to access or use the Website or App after any modifications, you agree to be bound by the revised Agreement.</p>

          <h2>Governing Law and Jurisdiction</h2>
          <p>This Agreement shall be governed by and construed in accordance with the laws of [insert applicable jurisdiction]. Any disputes arising under or in connection with this Agreement shall be subject to the exclusive jurisdiction of the courts in [insert applicable jurisdiction].</p>

          <h2>Contact Us</h2>
          <p>If you have any questions or concerns about this Agreement or the use of the Website or App, please contact us at [contact information].</p>




          
        </div>
      </div>
    </>
  )
}

export default Terms;